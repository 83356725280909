import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reset } from "../../../state/input-feedback/actions";
import Keycode from "../../fundamental/keycode/Keycode";

export function enterKeyHandler(handler) {
  return (e) => {
    if (!new Keycode(e).isEnter()) return;
    handler(e);
  };
}

export function withInputFeedback(OriginalComponent) {
  const mapStateToProps = () => ({});
  const mapDispatchToProps = (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          reset,
        },
        dispatch
      ),
    };
  };
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class ResetInputFeedbackComponent extends React.Component {
      static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
      };

      componentDidMount() {
        this.props.actions.reset();
      }

      render() {
        return <OriginalComponent {...this.props} />;
      }
    }
  );
}

export const OPTIONS_AVAILABILITY = [
  { code: "true", label: "あり", orderNo: 1 },
  { code: "false", label: "なし", orderNo: 2 },
];

export const OPTIONS_ALLOWABILITY = [
  { code: "true", label: "可", orderNo: 1 },
  { code: "false", label: "不可", orderNo: 2 },
];

export const OPTIONS_ALLOWABILITY_FUZZY = [
  { code: "true", label: "可", orderNo: 1 },
  { code: "false", label: "場合による", orderNo: 2 },
];

export const OPTIONS_PRACTICABILITY = [
  { code: "true", label: "実施", orderNo: 1 },
  { code: "false", label: "未実施", orderNo: 2 },
];

export const OPTIONS_AVAILABILITY_WITH_NEGOTIABLE = [
  { code: "POSITIVE", label: "あり", orderNo: 1 },
  { code: "NEGATIVE", label: "なし", orderNo: 2 },
  { code: "NEUTRAL", label: "要相談", orderNo: 3 },
];

export const OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE = [
  { code: "POSITIVE", label: "可", orderNo: 1 },
  { code: "NEGATIVE", label: "不可", orderNo: 2 },
  { code: "NEUTRAL", label: "要相談", orderNo: 3 },
];

export const OPTIONS_TAIO_JOKYO = [
  { code: "POSITIVE", label: "可", orderNo: 1 },
  { code: "NEGATIVE", label: "不可", orderNo: 2 },
  { code: "NEUTRAL", label: "要相談", orderNo: 3 },
];

export const OPTIONS_TAIO_JOKYO_WITH_IRYO = [
  { code: "AVAILABLE", label: "対応可", orderNo: 4 },
  { code: "POSITIVE", label: "受入可", orderNo: 1 },
  { code: "NEGATIVE", label: "不可", orderNo: 2 },
  { code: "NEUTRAL", label: "要相談", orderNo: 3 },
];

export const OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT = [
  { code: "POSITIVE", label: "可", orderNo: 1 },
  { code: "NEUTRAL", label: "要相談", orderNo: 2 },
];

export const OPTION_TEIKYO_BUSU = [
  { code: "1", label: "1部制", orderNo: 1 },
  { code: "2", label: "2部制", orderNo: 2 },
  { code: "3", label: "3部制", orderNo: 3 },
  { code: "4", label: "4部制", orderNo: 4 },
  { code: "5", label: "5部制", orderNo: 5 },
];
