import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../state/authentication/actions";
import { push } from "react-router-redux";
import LoginForm from "./LoginForm";
import Mount from "../ui/layout/Mount";
import AuthenticationUserLoad from "./AuthenticationUserLoad";
import LoginRedirect from "./LoginRedirect";
import Pathname from "../naming/Pathname";
import Parametername from "../naming/Parametername";
import Center from "../ui/layout/Center";
import AssignmentIcon from "@material-ui/icons/AssignmentInd";
import LockIcon from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/HelpOutline";
import EmailRegistrationDialog from "../ui/dialog/EmailRegistrationDialog";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleAgreementLinkClick = this.handleAgreementLinkClick.bind(this);
    this.handlePrivacyPolicyLinkClick = this.handlePrivacyPolicyLinkClick.bind(
      this
    );
    this.handleMilmoPlanLinkClick = this.handleMilmoPlanLinkClick.bind(this);
  }

  handleAgreementLinkClick() {
    this.props.actions.gotoAgreementPage();
  }

  handlePrivacyPolicyLinkClick() {
    this.props.actions.gotoPrivacyPolicyPage();
  }
  handleMilmoPlanLinkClick() {
    this.props.actions.gotoMilmoPlanPage();
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.resetLoginForm();
    actions.loadUser();
  }

  render() {
    const {
      user,
      userIsUnauthenticated,
      userWasLoaded,
    } = this.props.authentication;
    // メールアドレス未登録ユーザは、メールアドレス登録ダイアログを表示
    if (user?.mailAddress === "" || user?.mailAddress === null) {
      return <EmailRegistrationDialog user={user} />;
    }
    // アカウントデータがロードできたらリダイレクト
    if (userWasLoaded) return <LoginRedirect user={user} />;
    // できなければログインフォームを表示
    if (userIsUnauthenticated) return this.renderMain();
    // 待機中は空表示
    return null;
  }

  renderMain() {
    const {
      loginIsProcessing,
      loginWasAccepted,
      loginWasDenied,
    } = this.props.authentication;
    return (
      <div className="webfront">
        <div className="l-login-frame p-login">
          <div className="p-login__logo">
            <img src="/images/logo.svg" />
          </div>
          <div className="p-login__content">
            <div className="p-login-chara">
              <div className="p-login-chara__img">
                <img src="/images/chara_jump.png" />
              </div>
              <div className="p-login-chara__msg">
                下の入力フォームに<span>「ユーザー名」「パスワード」</span>
                を入れて、
                <br />
                <span>「ログイン」ボタン</span>を押してね!!
              </div>
            </div>
            {this.renderLoginForm()}
            <div className="p-login-message">
              <Mount if={loginWasAccepted}>
                <AuthenticationUserLoad />
              </Mount>
              <Mount if={loginIsProcessing}>{this.renderAcceptMessage()}</Mount>
              <Mount if={loginWasDenied}>{this.renderErrorMessage()}</Mount>
            </div>
            {this.renderExternalLink()}
          </div>
          <div className="p-login__footer">
            <div className="p-login-footer-item">
              <a onClick={this.handleAgreementLinkClick}>利用規約</a>
              <a onClick={this.handlePrivacyPolicyLinkClick}>
                プライバシーポリシー
              </a>
            </div>
            <div className="p-login-footer-item">
              <a onClick={this.handleMilmoPlanLinkClick}>ミルモぷらん</a>
            </div>
            <div className="p-login-footer-item">
              <span className="coworker">
                協働開発：地方独立行政法人東京都健康長寿医療センター研究所
              </span>
              <span className="copyrights">
                © Welmo, Inc. All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLoginForm() {
    const { authentication } = this.props;
    const bindingProps = {
      form: authentication.loginForm,
      loginIsProcessing: authentication.loginIsProcessing,
    };
    return <LoginForm {...bindingProps} />;
  }

  renderAcceptMessage() {
    return <div className="p-login-message__loading">ログインしています。</div>;
  }

  renderErrorMessage() {
    return (
      <div className="p-login-message__error">
        ログインできませんでした。ログインIDとパスワードをご確認ください。
      </div>
    );
  }

  renderExternalLink() {
    return (
      <div className="p-login-external-link">
        <Center>
          <ul>
            <li>
              <AssignmentIcon />
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeFmYBItmxIW9XoKjIf7goSqNSu8R48Po_FnQQrIYy_H665vg/viewform">
                新規お申込みはこちら
              </a>
            </li>
            <li>
              <LockIcon />
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfChSYyN2CAEkDDhAQMn5g6WINFkp7AwEwZ4iieiQa33Os6og/viewform">
                ユーザー名/パスワードを忘れた方はこちら
              </a>
            </li>
            <li>
              <HelpIcon />
              <a href="https://welmo.co.jp/service/milmo-net/">
                ミルモネットについて知りたい方はこちら
              </a>
            </li>
          </ul>
        </Center>
      </div>
    );
  }
}

LoginPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  authentication: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  const pathname = window.location.href.substr(
    window.location.protocol.length + "//".length + window.location.host.length
  );
  const searchParams = new URLSearchParams();
  searchParams.set(Parametername.pathname, pathname);
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoAgreementPage: () =>
        dispatch(
          push({
            pathname: Pathname.agreement,
            search: searchParams.toString(),
          })
        ),
      gotoPrivacyPolicyPage: () => {
        window.open("https://welmo.co.jp/privacy");
      },
      gotoMilmoPlanPage: () => {
        window.open("https://milmoplan.welmo.co.jp");
      },
    },
  };
}
