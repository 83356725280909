import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function requestBlankForm(groupId, role, randomPw) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getBlankForm(dispatch, groupId, role, randomPw);
  };
}

export function requestEditForm(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getEditForm(dispatch, id);
  };
}

export function requestEditFormBySysop(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    const url = ApiPath.api_tools_sysop_id_editform.replace(/:id/, id);
    getForm(dispatch, url, "edit");
  };
}

export function requestPasswordForm(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getPasswordForm(dispatch, id);
  };
}

export function requestPasswordFormBySysop(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    const url = ApiPath.api_tools_sysop_id_password.replace(/:id/, id);
    getForm(dispatch, url, "password");
  };
}

export function changeForm(name, value) {
  return {
    type: ActionType.CHANGE_FORM,
    payload: {
      name,
      value,
    },
  };
}

export function submitToAdd(form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToAdd(dispatch, form, callback);
  };
}

export function submitToAddBySysop(form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    const url = ApiPath.api_tools_sysop;
    post(dispatch, url, form, callback, "アカウントを作成しました。");
  };
}

export function submitToEdit(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEdit(dispatch, id, form, callback);
  };
}

export function submitToEditBySysop(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    const url = ApiPath.api_tools_sysop_id.replace(/:id/, id);
    post(dispatch, url, form, callback, "アカウント情報を変更しました。");
  };
}

export function submitToAddEmail(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToAddEmail(dispatch, id, form, callback);
  };
}

export function submitToChangePassword(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToChangePassword(dispatch, id, form, callback);
  };
}

export function submitToChangePasswordBySysop(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    const url = ApiPath.api_tools_sysop_id_password.replace(/:id/, id);
    post(
      dispatch,
      url,
      form,
      callback,
      "アカウントのパスワードを変更しました。"
    );
  };
}

export function submitToRemove(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    const url = ApiPath.api_accounts_id.replace(/:id/, id);
    del(dispatch, url, callback);
  };
}

export function submitToRemoveBySysop(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    const url = ApiPath.api_tools_sysop_id.replace(/:id/, id);
    del(dispatch, url, callback);
  };
}

function getBlankForm(dispatch, groupId, role, randomPw) {
  const searchParams = new URLSearchParams();
  searchParams.set("groupId", groupId);
  searchParams.set("role", role);
  const search = searchParams.toString();
  const url = `${ApiPath.api_accounts_blankform}?${search}`;
  getForm(dispatch, url, "blank", randomPw);
}

function getEditForm(dispatch, id) {
  const url = ApiPath.api_accounts_id_editform.replace(/:id/, id);
  getForm(dispatch, url, "edit");
}

function getPasswordForm(dispatch, id) {
  const url = ApiPath.api_accounts_id_password.replace(/:id/, id);
  getForm(dispatch, url, "password");
}

function getForm(dispatch, url, type, randomPw) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        form: json,
        type,
        randomPw,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToAdd(dispatch, form, callback) {
  const url = ApiPath.api_accounts;
  post(dispatch, url, form, callback, "アカウントを作成しました。");
}

function postToEdit(dispatch, id, form, callback) {
  const url = ApiPath.api_accounts_id.replace(/:id/, id);
  post(dispatch, url, form, callback, "アカウント情報を変更しました。");
}

function postToAddEmail(dispatch, id, form, callback) {
  const url = ApiPath.api_accounts_id_email.replace(/:id/, id);
  post(dispatch, url, form, callback, "メールアドレスを登録しました。");
}

function postToChangePassword(dispatch, id, form, callback) {
  const url = ApiPath.api_accounts_id_password.replace(/:id/, id);
  post(dispatch, url, form, callback, "アカウントのパスワードを変更しました。");
}

function post(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function del(dispatch, url, callback = () => {}) {
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.delete(url).request(
    withCompletionMessage(
      dispatch,
      "アカウントを削除しました。"
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
