import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import LoginRedirect from "../../login/LoginRedirect";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import RaisedSecondaryButton from "../../../app/ui/button/RaisedSecondaryButton";
import InputTypeText from "../form/InputTypeText";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/account-form/actions";
import StaticLabel from "../form/StaticLabel";
import HorizontalSmallLabelLayout from "../form/HorizontalSmallLabelLayout";

class EmailRegistrationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleDialogCloseClick = this.handleDialogCloseClick.bind(this);
    this.handleEmailRegistrationButtonClick = this.handleEmailRegistrationButtonClick.bind(
      this
    );
  }

  state = {
    closedEmailRegistration: false,
    showDialog: true,
  };

  componentDidMount() {
    const storedClosedEmailRegistration =
      JSON.parse(sessionStorage.getItem("closedEmailRegistration")) || false;
    this.setState({ closedEmailRegistration: storedClosedEmailRegistration });
  }

  handleDialogCloseClick() {
    this.setState({ closedEmailRegistration: true });
    this.setState({ showDialog: false });
    sessionStorage.setItem("closedEmailRegistration", "true");
    this.closeValidationMessage();
  }

  handleEmailRegistrationButtonClick() {
    const { actions, accountForm, user } = this.props;
    this.closeValidationMessage();
    actions.submitToAddEmail(
      user.id,
      accountForm.form,
      this.handleDialogCloseClick
    );
  }

  closeValidationMessage() {
    const { notificationMessage } = this.props;
    notificationMessage.open = false;
  }

  createBindingProps() {
    const { actions, accountForm } = this.props;
    const { form } = accountForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    const { user } = this.props;
    const { showDialog, closedEmailRegistration } = this.state;
    const bindingProps = this.createBindingProps();

    if (!showDialog || closedEmailRegistration) {
      return <LoginRedirect user={user} />;
    }

    return (
      <Fragment>
        <Dialog
          onClose={this.handleDialogCloseClick}
          open={this.state.showDialog}
          PaperProps={{
            style: { width: "500px", maxWidth: "90%" },
          }}
        >
          <DialogTitle>
            メールアドレス登録
            <StaticLabel>※メールアドレスの登録が必要になりました</StaticLabel>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={this.handleDialogCloseClick}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <HorizontalSmallLabelLayout labelText="">
              <InputTypeText
                name="mailAddress"
                hintText="xxxx@xxxx.xxxx"
                {...bindingProps}
              />
            </HorizontalSmallLabelLayout>
          </DialogContent>
          <DialogActions>
            <RaisedSecondaryButton
              onClick={this.handleEmailRegistrationButtonClick}
            >
              登録
            </RaisedSecondaryButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

EmailRegistrationDialog.propTypes = {
  accountForm: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notificationMessage: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailRegistrationDialog)
);

function mapStateToProps(state) {
  return {
    accountForm: state.accountForm || { form: {} },
    notificationMessage: state.notificationMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
