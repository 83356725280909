import ActionType from "./reducer/ActionType";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";

export function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function request(
  id,
  serviceSubId,
  serviceContentType,
  options,
  callback
) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    requestContent(
      dispatch,
      id,
      serviceSubId,
      serviceContentType,
      options,
      callback
    );
  };
}

export function flierDownloadCountUp(serviceId, fileId) {
  return (dispatch) => {
    dispatch({
      type: "",
    });
    postCountUp(dispatch, serviceId, fileId);
  };
}

export function appendCommentToVisibleSurface(dummyComment) {
  return {
    type: ActionType.APPEND_COMMENT_TO_VISIBLE_SURFACE,
    payload: { dummyComment },
  };
}

export function modifyCommentOnVisibleSurface(dummyComment) {
  return {
    type: ActionType.MODIFY_COMMENT_ON_VISIBLE_SURFACE,
    payload: { dummyComment },
  };
}

export function removeCommentFromVisibleSurface(id) {
  return {
    type: ActionType.REMOVE_COMMENT_FROM_VISIBLE_SURFACE,
    payload: { id },
  };
}

// 事業所指定のリクエスト（印刷、詳細）
function requestContent(
  dispatch,
  id,
  serviceSubId,
  serviceContentType,
  options = {},
  callback = () => {}
) {
  const url = createUrl(id, serviceSubId, serviceContentType, options);
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        id,
        serviceContentType,
        contents: { ...json, ...options },
      },
    });
    setTimeout(() => callback(id, serviceContentType));
  };

  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function createUrl(id, serviceSubId, serviceContentType, options) {
  options = {
    ...{ preview: false, print: false },
    ...options,
  };
  const searchParams = new URLSearchParams();
  if (serviceSubId) searchParams.set(Parametername.serviceSubId, serviceSubId);
  if (options.preview) searchParams.set(Parametername.preview, "yes");
  if (options.print) searchParams.set(Parametername.print, "yes");
  let url = ApiPath.api_services_id_type
    .replace(/:id/, id)
    .replace(/:type/, serviceContentType.name);
  return `${url}?${searchParams.toString()}`;
}

function postCountUp(dispatch, serviceId, fileId) {
  const url = ApiPath.api_services_id_flier_event
    .replace(/:id/, serviceId)
    .replace(/:fileId/, fileId);
  const onSuccess = () => {};
  ApiRequest.post(url, "").request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}
